import { useState, useEffect } from 'react';
import CustomDataGrid from '../../Components/CustomDataGrid/CustomDataGrid';
import CircleButton from '../../Components/CustomButtons/CircleButton';
import config from '../../config/config';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import EditButton from '../../Components/CustomButtons/EditButton';

import classes from './OrganizationsList.module.css';

const { organizationPrefix } = config;

export const OrganizationsList = () => {
	const [organizationList, setOrganizationList] = useState([]);
	const axiosPrivate = useAxiosPrivate();

	const loc = 'ru-Ru';
	const payOptions = { style: 'currency', currency: 'RUB' };

	useEffect(() => {
		async function getOrganizations() {
			try {
				const { data } = await axiosPrivate.get(organizationPrefix);
				setOrganizationList(data);
			} catch (error) {
				console.log(error);
			}
		}
		getOrganizations();
	}, [axiosPrivate]);

	const columns = [
		{
			field: 'name',
			headerName: 'Название',
			width: 200,
			editable: false,
		},
		{
			field: 'limit',
			headerName: 'Долговой лимит',
			width: 200,
			editable: false,
			renderCell: (params) => {
				return new Intl.NumberFormat(loc, payOptions).format(params.value);
			},
		},
		{
			field: 'action',
			headerName: 'Действия',
			width: 150,
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'actionStyle',
			disableColumnMenu: true,
			sortable: false,
			renderCell: (params) => {
				return <EditButton link={'/organizationsList/' + params?.row._id} />;
			},
		},
	];
	return (
		<main className={classes.wrapper}>
			<h1>Компании</h1>
			<CircleButton link={'/organizationsList/addOrganization'} />
			<CustomDataGrid rows={organizationList} columns={columns} />
		</main>
	);
};
