import { Button, TextField } from '@mui/material';
import PushPinIcon from '@mui/icons-material/PushPin';

import classes from './PortalNote.module.css';

export const PortalNote = ({ note, onChange, onClick }) => {
	return (
		<div className={classes.wrapper}>
			<TextField
				label="Введите текст примечания"
				type="text"
				value={note}
				onChange={onChange}
				fullWidth
				multiline
				minRows={2}
			/>
			<Button variant="contained" onClick={onClick}>
				<PushPinIcon />
			</Button>
		</div>
	);
};
