import { NavLink } from 'react-router-dom';
import classes from './sideBar.module.css';
import { Groups } from '@mui/icons-material';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PersonIcon from '@mui/icons-material/Person';
export default function Sidebar() {
	const linkActive = (isActive) => {
		return isActive
			? `${classes.linkSideBar} ${classes.active}`
			: classes.linkSideBar;
	};
	return (
		<div className={classes.sidebar}>
			<div className={classes.sidebarWrapper}>
				{/* <div className={classes.sidebarMenu}>
					<h3 className={classes.sidebarTitle}>Пользователи</h3>
					<ul className={classes.sidebarList}>
						<NavLink to="/users" className={classes.linkSideBar}>
							<li className={classes.sidebarListItem}>
								<PermIdentity className={classes.sidebarIcon} />
								Пользователи
							</li>
						</NavLink>					
					</ul>
				</div> */}
				<div className={classes.sidebarMenu}>
					<h3 className={classes.sidebarTitle}>Инфо</h3>
					<ul className={classes.sidebarList}>
						<li className={classes.sidebarListItem}>
							<NavLink
								to="/"
								end
								className={({ isActive }) => linkActive(isActive)}
							>
								<ReceiptLongIcon className={classes.sidebarIcon} />
								Список задолженностей
							</NavLink>
						</li>
						<li className={classes.sidebarListItem}>
							<NavLink
								to="/reports"
								className={({ isActive }) => linkActive(isActive)}
							>
								<ReceiptLongIcon className={classes.sidebarIcon} />
								Отчеты
							</NavLink>
						</li>
					</ul>
				</div>
				<div className={classes.sidebarMenu}>
					<h3 className={classes.sidebarTitle}>Справочники</h3>

					<ul className={classes.sidebarList}>
						<li className={classes.sidebarListItem}>
							<NavLink
								to="/organizationsList"
								className={({ isActive }) => linkActive(isActive)}
							>
								<Groups className={classes.sidebarIcon} />
								Компании
							</NavLink>
						</li>
						<li className={classes.sidebarListItem}>
							<NavLink
								to="/creditFormList"
								className={({ isActive }) => linkActive(isActive)}
							>
								<ReceiptLongIcon className={classes.sidebarIcon} />
								Формы долга
							</NavLink>
						</li>
						<li className={classes.sidebarListItem}>
							<NavLink
								to="/users"
								className={({ isActive }) => linkActive(isActive)}
							>
								<PersonIcon className={classes.sidebarIcon} />
								Пользователи
							</NavLink>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
}
