import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";

export const ChangePasswordDialog = ({
  isOpen,
  handleClose,
  changePassword,
  changePasswordInfo,
}) => {
  const [password, setPassword] = useState("");

  const all = (id, password) => {
    changePassword(id, password);
    setPassword("");
  };
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {`Вы собираетесь сменить пароль пользователя ${changePasswordInfo.name}`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <TextField
          fullWidth
            id="filled-password-input"
            label="Введите новый пароль"
            type="password"
            variant="filled"
            onChange={(event) => setPassword(event.target.value)}
            value={password}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={handleClose}
          variant="contained"
          color="secondary"
        >
          Отменить
        </Button>
        <Button
          onClick={() => all(changePasswordInfo.id, password)}
          variant="contained"
          color="primary"
        >
          Сменить пароль
        </Button>
      </DialogActions>
    </Dialog>
  );
};
