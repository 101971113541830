import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Button,
	TextField,
	Select,
	MenuItem,
	InputLabel,
	Checkbox,
} from '@mui/material';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import config from '../../../config/config';
import { InputWithCategory } from '../../../Components/InputWithCategory/InputWithCategory';
import {
	calculateNearestPreviousWeekday,
	addDays,
} from '../../../utils/calculateDays';
import ErrorMessage from '../../../Components/ErrorMessage/ErrorMessage';
import classes from './AddTranche.module.css';

const { tranchePrefix, organizationPrefix, creditFormPrefix } = config;

export const AddTranche = () => {
	const navigate = useNavigate();
	const axiosPrivate = useAxiosPrivate();

	const loc = 'ru-Ru';
	const payOptions = { style: 'currency', currency: 'RUB' };

	const [tranche, setTranche] = useState({
		org: '',
		creditForm: '',
		date: new Date(),
		trancheDay: 90,
		debt: 0,
		info: '',
	});
	const [checked, setChecked] = useState(false);
	const [orgList, setOrgList] = useState([]); //список всех организаций
	const [creditFormList, setCreditFormList] = useState([]); //список всех форм кредита
	const [tranchesList, setTranchesList] = useState([]); //список всех траншей

	const currentTraches = tranchesList?.filter(
		(el) => el?.org?._id === tranche?.org
	); //находит транши выбранной организации

	// const limitDebt = currentTraches?.length
	// 	? currentTraches?.reduce(
	// 			(accumulator, currentValue) => accumulator + currentValue.debt,
	// 			0.0
	// 	  )
	// 	: 0; // подсчитываем задолженность всех траншей выбранной организации

	const limitBalance = currentTraches?.length
		? currentTraches?.reduce(
				(accumulator, currentValue) => accumulator + currentValue.balance,
				0.0
		  )
		: 0; // подсчитываем текущую задолженность всех траншей выбранной организации
	const currentOrg = orgList.find((el) => el._id === tranche.org); //находим выбранную организации из общего списка

	// const currentRepay = currentOrg?.totalRepay || 0;

	const [message, setMessage] = useState('');
	const handleChangeCheckBox = (event) => {
		setChecked(event.target.checked);
	};

	useEffect(() => {
		async function getDirectories() {
			try {
				const org = await axiosPrivate.get(`${organizationPrefix}/complete`);
				const creditForms = await axiosPrivate.get(creditFormPrefix);
				const tranche = await axiosPrivate.get(tranchePrefix);
				setCreditFormList(creditForms.data);
				setOrgList(org.data);
				setTranchesList(tranche?.data);
			} catch (error) {
				console.log(error);
			}
		}
		getDirectories();
	}, []);

	const handleChange = (e) => {
		setTranche((prev) => ({ ...prev, [e.target.name]: e.target?.value }));
	};

	async function createTranche(event) {
		try {
			event.preventDefault();
			const targetValue = document.getElementById('debt');

			const allDays = addDays(tranche.date, tranche.trancheDay);
			const returnTranche = calculateNearestPreviousWeekday(allDays);
			const curOrg = orgList.find((el) => el._id === tranche.org);
			const curCreditForm = creditFormList.find(
				(el) => el._id === tranche.creditForm
			);

			const form = new FormData(event.currentTarget);
			form.append('real', checked ? false : true);
			form.append('returnTranche', returnTranche);
			form.append('balance', targetValue.value);
			const f_data = Object.fromEntries(form.entries());

			f_data.org = curOrg;
			f_data.creditForm = curCreditForm;
			axiosPrivate.post(`${tranchePrefix}`, f_data);
			navigate(-1);
			setMessage('');
		} catch (error) {
			setMessage(error?.response?.data?.message);
		}
	}

	return (
		<div className={classes.wrapper}>
			<h1>Новая задолженность</h1>
			<form onSubmit={createTranche} className={classes.form}>
				<div className={classes.CheckBoxStyle}>
					<Checkbox
						id="real"
						checked={checked}
						onChange={handleChangeCheckBox}
						inputProps={{ 'aria-label': 'controlled' }}
						size="large"
					/>
					<label htmlFor="real">Планируемое погашение </label>
				</div>
				<div>
					<InputLabel id="org">Организация</InputLabel>
					<Select
						labelId="org"
						id="org"
						sx={{ width: '100%' }}
						value={tranche.org.name}
						name="org"
						label="org"
						onChange={handleChange}
						required
					>
						{orgList?.map((item) => (
							<MenuItem key={item?._id} value={item?._id} name={item?.name}>
								{item?.name}
							</MenuItem>
						))}
					</Select>
				</div>
				<div>
					<InputLabel id="creditForm">Форма долга</InputLabel>
					<Select
						sx={{ width: '100%' }}
						labelId="creditForm"
						id="creditForm"
						value={tranche.creditForm}
						name="creditForm"
						label="creditForm"
						onChange={handleChange}
						required
					>
						{creditFormList?.map((item) => (
							<MenuItem key={item?._id} value={item?._id}>
								{item?.name}
							</MenuItem>
						))}
					</Select>
				</div>
				<div>
					<h3>Долговой лимит для организации:</h3>
					{new Intl.NumberFormat(loc, payOptions).format(
						currentOrg?.limit - limitBalance || 0
					)}
				</div>
				<InputLabel>Сумма долга</InputLabel>
				<InputWithCategory name="debt" />
				<div>
					<TextField
						fullWidth
						name="date"
						label="Дата операции"
						variant="outlined"
						onChange={handleChange}
						value={new Date(tranche?.date).toISOString().split('T')[0]}
						autoComplete="off"
						type="date"
					/>
				</div>
				<div>
					<TextField
						fullWidth
						name="trancheDay"
						label="Срок возврата (дней)"
						variant="outlined"
						onChange={handleChange}
						value={tranche?.trancheDay}
						autoComplete="off"
						type="number"
						inputProps={{ min: 0 }}
					/>
				</div>
				<TextField
					name="info"
					label="Информация о заявке"
					value={tranche.info}
					onChange={handleChange}
					rows={4}
					multiline
				/>
				{/* <div>
					<InputLabel>Дата заключения договора</InputLabel>
					<TextField
						fullWidth
						name="inDate"
						variant="outlined"
						onChange={handleChange}
						value={new Date(tranche?.inDate).toISOString().split('T')[0]}
						autoComplete="off"
						type="date"
					/>
				</div> */}

				{/* <InputLabel>Баланс</InputLabel> */}
				{/* <InputWithCategory name="balance" /> */}
				<ErrorMessage message={message} />
				<Button
					variant="contained"
					color="primary"
					size="large"
					type="submit"
					// onClick={(event) => createTranche(event)}
				>
					Создать
				</Button>
			</form>
		</div>
	);
};
