import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './newUser.module.css';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import config from '../../../config/config';
import { Button, TextField } from '@mui/material';
import ErrorMessage from '../../../Components/ErrorMessage/ErrorMessage';

const NewUser = () => {
	const axiosPrivate = useAxiosPrivate();
	let navigate = useNavigate();

	const [user, setUser] = useState({
		name: '',
		email: '',
		position: '',
		password: '',
	});

	const [message, setMessage] = useState('');

	const handleChange = (e) => {
		setUser((prev) => ({ ...prev, [e.target.name]: e.target?.value }));
	};

	async function createUserAdm(event) {
		try {
			event.preventDefault();
			const f_data = new FormData();
			f_data.append('name', user?.name);
			f_data.append('email', user?.email);
			f_data.append('position', user?.position);
			f_data.append('password', user?.password);
			const response = await axiosPrivate.post(
				`/${config?.adminPrefix}/registration`,
				f_data
			);
			navigate('/users');
			setMessage('');
		} catch (error) {
			setMessage(error?.response?.data?.message);
		}
	}

	return (
		<>
			<div className={classes.newUser}>
				<h1 className={classes.newUserTitle}>Новый пользователь</h1>
				<ErrorMessage message={message} />
				<div className={classes.newUserForm}>
					<div className={classes.newUserForm}>
						<div className={classes.newUserItem}>
							<TextField
								fullWidth
								label="ФИО"
								name="name"
								variant="outlined"
								onChange={handleChange}
								value={user?.name}
								autoComplete="off"
							/>
						</div>

						<div className={classes.newUserItem}>
							<TextField
								fullWidth
								label="Email"
								name="email"
								variant="outlined"
								onChange={handleChange}
								value={user?.email}
								autoComplete="off"
							/>
						</div>

						<div className={classes.newUserItem}>
							<TextField
								fullWidth
								label="Должность"
								name="position"
								variant="outlined"
								onChange={handleChange}
								value={user?.position}
								autoComplete="organization-title"
							/>
						</div>

						<div className={classes.newUserItem}>
							<TextField
								fullWidth
								label="пароль"
								name="password"
								variant="outlined"
								onChange={handleChange}
								value={user?.password}
								autoComplete="new-password"
								type="password"
							/>
						</div>
						<div className={classes.newUserItem}>
							<Button
								variant="contained"
								color="primary"
								size="large"
								onClick={(event) => createUserAdm(event)}
							>
								Создать
							</Button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default NewUser;
