export function calculateNearestPreviousWeekday(date) {
	const dayOfWeek = date.getDay();

	// Если текущий день - выходной, уменьшаем дату на 1 день
	if (dayOfWeek === 0 || dayOfWeek === 6) {
		date.setDate(date.getDate() - 1);
	}

	// Пока день недели равен 0 (воскресенье) или 6 (суббота), уменьшаем дату на 1 день
	while (date.getDay() === 0 || date.getDay() === 6) {
		date.setDate(date.getDate() - 1);
	}

	return date;
}

export function addDays(date, days) {
	const newDate = new Date(date);
	const sumDays = newDate.getDate() + Number(days);
	newDate.setDate(sumDays);
	return newDate;
}
