import {
	useState,
	useEffect,
	useLayoutEffect,
	useReducer,
	useMemo,
} from 'react';
import CustomDataGrid from '../../Components/CustomDataGrid/CustomDataGrid';
import CircleButton from '../../Components/CustomButtons/CircleButton';
import config from '../../config/config';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import EditButton from '../../Components/CustomButtons/EditButton';
import DeleteButton from '../../Components/CustomButtons/DeleteButton';
import DeleteDialog from '../../Components/Dialog/DeleteDialog';
import InfoIcon from '@mui/icons-material/Info';
import { InfoDialog } from '../../Components/Dialog/InfoDialog';
import OperationButton from '../../Components/CustomButtons/OperationButton';
import { ButtonPopover } from '../../Components/Popover/ButtonPopover';
import { GreenSwitch } from '../../config/some';

// import OperationForm from '../../Components/Form/OperationForm';

import classes from './Tranches.module.css';
import { Button } from '@mui/material';
import { FilterList } from '../../Components/FilterList/FilterList';
import clsx from 'clsx';
import {
	getOrgLimitByFilterName,
	getRestOrgLimit,
} from './helpers/getCurrentLimit';
import { ShowRestLimit } from '../../Components/ShowRestLimit/ShowRestLimit';
import {
	addInitialFilterItems,
	addNewFilterItems,
	filterReducer,
} from './helpers/filter.reducer';
import { addFirstTrancheData } from './helpers/addFirstTrancheData';

const { tranchePrefix, organizationPrefix } = config;

export const Tranches = () => {
	const axiosPrivate = useAxiosPrivate();
	const loc = 'ru-Ru';
	const payOptions = { style: 'currency', currency: 'RUB' };

	//список задолженностей
	const [tranchesList, setTranchesList] = useState([]);
	//список организаций
	let [orgList, setOrgList] = useState([]);
	const [orgNames, setOrgNames] = useState([]);
	//фильтрация по организациям
	const [filterOrgNames, setFilterOrgNames] = useReducer(filterReducer, []);
	//Добавим поле даты первого транша для валидации даты при погашении
	orgList = useMemo(
		() => addFirstTrancheData(orgList, tranchesList),
		[orgList, tranchesList]
	);
	// console.log('New orgList: ', orgList);
	//Колбэк для компонента фильтрации
	const choiceFilterNames = (filterName) => {
		//Диспатчим в фильтр через генератор экшена новые компании
		setFilterOrgNames(addNewFilterItems(filterName));
	};
	//Сохраняем параметры фильтра
	useLayoutEffect(() => {
		if (filterOrgNames.length > 0) {
			localStorage.setItem('currentFilterNames', filterOrgNames.join(','));
		}
	}, [filterOrgNames]);

	//фильтрация по организациям и по балансу (больше 1 коп)
	const filteredTranceList = tranchesList.filter(
		(item) => filterOrgNames.includes(item.orgName) && item.balance > 0.0099
		//Проблема в хранении на сервере чисел после точки
	);
	//получаем лимит для фильтрованной организации или суммируем всех
	const orgLimit = getOrgLimitByFilterName(orgList, filterOrgNames);
	//получаем статок лимита
	const restOrgLimit = getRestOrgLimit(filteredTranceList, orgLimit);
	console.log(filteredTranceList);
	const [open, setOpen] = useState(false); //модалка удаления
	const [idDelete, setIdDelete] = useState('');
	const [currentTranche, setCurrentTranche] = useState({
		info: [],
		id: '',
	}); // стейт примечения и id выбранной транзакции
	const [openInfo, setOpenInfo] = useState(false); // модульное окно для примечания
	const [loading, setLoading] = useState(false);

	//Забираем с сервера транши, сортируем, добавляем св-ва, сетаем в стейт
	const getTranches = async () => {
		try {
			const { data } = await axiosPrivate.get(tranchePrefix);
			//sorted tranches by date
			data.sort(
				(a, b) => Date.parse(a.returnTranche) - Date.parse(b.returnTranche)
			);
			//add orgName for sort in dataGrid
			//add restDays
			data.forEach((item) => {
				item.orgName = item.org.name;
				//от даты погашения (в мсек) отнимаем текущую дату (в сутках 86400000 мсек)
				item.restDays = Math.ceil(
					(Date.parse(item.returnTranche) - Date.parse(new Date())) / 86400000
				);
			});
			setTranchesList(data);
		} catch (error) {
			console.log(error);
		}
	};

	const preparedCheckInfo = (info, id) => {
		setCurrentTranche({ info, id });
		setOpenInfo(true);
	};

	useEffect(() => {
		async function getDirectories() {
			try {
				const org = await axiosPrivate.get(`${organizationPrefix}/complete`);
				setOrgList(org.data);
				//устанавливаем имена организаций как значения для фильтрации
				const orgNamesList = org.data.map((item) => item.name);
				setOrgNames(orgNamesList);
				//Установим параметры фильтрации
				const savedFilterNamesString =
					localStorage.getItem('currentFilterNames');
				const filterNames = savedFilterNamesString
					? savedFilterNamesString.split(',')
					: orgNamesList;
				setFilterOrgNames(addInitialFilterItems(filterNames));
			} catch (error) {
				console.log(error);
			}
		}
		getDirectories();
		getTranches();
	}, []);

	const prepareDelete = async (id, name, debt) => {
		setIdDelete({ id, name, debt });
		setOpen(true);
	};

	const handleDelete = () => {
		deleteTranche(idDelete?.id);
		setOpen(false);
	};

	const deleteTranche = async (id) => {
		try {
			await axiosPrivate.delete(`${tranchePrefix}/${id}`);
			getTranches();
		} catch (error) {
			console.log(error);
		}
	};

	const handleChangeEnabled = async (event, id) => {
		try {
			setLoading(true);
			const enabled = event?.target?.checked;
			const response = await axiosPrivate.put(`${tranchePrefix}/real/${id}`, {
				real: enabled,
			});
			//mutate user state
			const itemIndex = tranchesList.findIndex(
				(item) => item._id === response.data._id
			);
			if (itemIndex > -1) {
				const updateItems = [...tranchesList];
				if (enabled) {
					updateItems[itemIndex] = {
						...updateItems[itemIndex],
						real: response.data.real,
					};
				} else {
					updateItems[itemIndex] = {
						...updateItems[itemIndex],
						real: response.data.real,
					};
				}

				setTranchesList(updateItems);
			}
			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	};

	const columns = [
		{
			field: 'real',
			headerName: 'Фактический долг',
			type: 'boolean',
			width: 100,
			editable: false,
			disableColumnMenu: true,
			headerAlign: 'center',
			renderCell: (params) => {
				return (
					<div className="styleButton">
						<GreenSwitch
							// {...label}
							checked={params?.row?.real}
							onChange={(event) => handleChangeEnabled(event, params?.row?._id)}
						/>
					</div>
				);
			},
		},
		{
			field: 'orgName',
			headerName: 'Организация',
			width: 150,
			editable: false,
		},
		{
			field: 'date',
			headerName: 'Дата взятия долга',
			width: 100,
			editable: false,
			renderCell: (params) => {
				return new Intl.DateTimeFormat(loc).format(new Date(params?.value));
			},
		},
		{
			field: 'trancheDay',
			headerName: 'Срок',
			width: 50,
			editable: false,
			align: 'center',
		},
		{
			field: 'creditForm',
			headerName: 'Форма долга',
			width: 200,
			editable: false,
			renderCell: (params) => {
				return params.row.creditForm.name;
			},
		},
		{
			field: 'balance',
			headerName: 'Текущий долг',
			width: 130,
			editable: false,
			renderCell: (params) => {
				return new Intl.NumberFormat(loc, payOptions).format(params.value);
			},
		},
		{
			field: 'returnTranche',
			headerName: 'Погасить до',
			width: 100,
			headerAlign: 'center',
			align: 'center',
			disableColumnMenu: true,
			sortable: true,
			editable: false,
			renderCell: (params) => {
				return new Intl.DateTimeFormat(loc).format(new Date(params?.value));
			},
		},
		{
			field: 'restDays',
			headerName: 'Осталось дней',
			width: 50,
			align: 'center',
			editable: false,
			cellClassName: (params) => {
				return clsx(
					classes.restDaysCell,
					params.row.restDays >= 0
						? classes.restDaysCellPositive
						: classes.restDaysCellNegative
				);
			},
			// valueGetter: (params) => params.row.
		},
		{
			field: 'actionInfo',
			headerName: 'Инфо',
			width: 70,
			disableColumnMenu: true,
			sortable: false,
			headerAlign: 'center',
			align: 'center',
			renderCell: (params) => {
				return (
					<Button
						sx={{
							minWidth: 30,
							paddingLeft: 1,
							paddingRight: 1,
						}}
						variant="contained"
						onClick={() =>
							preparedCheckInfo(params?.row?.info, params?.row?._id)
						}
					>
						<InfoIcon />
					</Button>
				);
			},
		},

		{
			field: 'action2',
			headerName: 'Погасить',
			width: 70,
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'actionStyle',
			disableColumnMenu: true,
			sortable: false,
			renderCell: (params) => {
				return <OperationButton link={'/transactions/' + params?.row._id} />;
			},
		},
		{
			field: 'action',
			headerName: 'Редактировать',
			width: 70,
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'actionStyle',
			disableColumnMenu: true,
			sortable: false,
			renderCell: (params) => {
				return <EditButton link={'/' + params?.row._id} />;
			},
		},
		{
			field: 'action1',
			headerName: 'Удалить',
			width: 70,
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'actionStyle',
			disableColumnMenu: true,
			sortable: false,
			renderCell: (params) => {
				return (
					<DeleteButton
						handleClick={() =>
							prepareDelete(
								params?.row?._id,
								params?.row?.org?.name,
								params?.row?.debt
							)
						}
					/>
				);
			},
		},
	];

	//resize columns by windows width
	if (window.innerWidth <= 1280) {
		columns.forEach((item) => {
			item.width = item.width * 0.9;
		});
	}

	return (
		<>
			<InfoDialog
				open={openInfo}
				setOpen={setOpenInfo}
				info={currentTranche.info}
				getData={getTranches}
				id={currentTranche.id}
				URL={`${tranchePrefix}/info`}
			/>
			<DeleteDialog
				textDialog={`Внимание! Вы собираетесь удалить задолженность ${
					idDelete.name
				} с долгом ${idDelete.debt?.toLocaleString(loc)} ₽`}
				handle={handleDelete}
				open={open}
				setOpen={setOpen}
			/>
			<main className={classes.wrapper}>
				<h1>Список задолженностей</h1>
				<div className={classes.btnPanel}>
					<CircleButton link={'/addTranche'} className={classes.circleButton} />
					{/* <OperationForm /> */}
					<ButtonPopover
						title="Погасить одной суммой"
						orgList={orgList}
						getData={getTranches}
					/>
					<ShowRestLimit
						filterOrgName={filterOrgNames}
						restOrgLimit={restOrgLimit}
					/>
				</div>
				<FilterList
					dataItems={orgNames}
					currentItems={filterOrgNames}
					choiceItems={choiceFilterNames}
				/>
				<CustomDataGrid
					rows={filteredTranceList}
					columns={columns}
					isLoading={loading}
					className={classes.table}
				/>
			</main>
		</>
	);
};
