import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextField } from '@mui/material';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import config from '../../../config/config';
import { InputWithCategory } from '../../../Components/InputWithCategory/InputWithCategory';

import classes from './AddOrganization.module.css';

const { organizationPrefix } = config;

export const AddOrganization = () => {
	const navigate = useNavigate();
	const axiosPrivate = useAxiosPrivate();

	const [organizations, setOrganizations] = useState({
		name: '',
		// inDate: new Date(),
		limit: 0,
	});

	const [message, setMessage] = useState('');

	const handleChange = (e) => {
		setOrganizations((prev) => ({ ...prev, [e.target.name]: e.target?.value }));
	};

	async function createOrganizations(event) {
		try {
			event.preventDefault();
			const form = new FormData(event.currentTarget);
			const f_data = Object.fromEntries(form.entries());
			const response = await axiosPrivate.post(`${organizationPrefix}`, f_data);
			navigate(-1);
			setMessage('');
		} catch (error) {
			setMessage(error?.response?.data?.message);
		}
	}

	return (
		<div className={classes.wrapper}>
			<h1>Новая организация</h1>
			<form onSubmit={createOrganizations} className={classes.form}>
				<TextField
					fullWidth
					name="name"
					variant="outlined"
					label="Название компании"
					onChange={handleChange}
					value={organizations?.name}
					autoComplete="off"
				/>

				<InputWithCategory name="limit" />

				<Button
					variant="contained"
					color="primary"
					size="large"
					type="submit"
					// onClick={(event) => createorganizations(event)}
				>
					Создать
				</Button>
			</form>
		</div>
	);
};
