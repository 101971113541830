import {
	changeDateFormat,
	changeCurrencyFormat,
} from '../../utils/changeFormat';

import classes from './ReportMainInfo.module.css';

export const ReportMainInfo = ({
	date,
	limit,
	balance,
	plannedRepay,
	balanceOfLimit,
}) => {
	return (
		<ul className={classes.dataList}>
			<li>
				Сегодня:
				<span>{changeDateFormat(date)}</span>
			</li>
			<li>
				Лимит:
				<span>{changeCurrencyFormat(limit)}</span>
			</li>
			<li>
				Задолженность на выбранную дату:
				<span>{changeCurrencyFormat(balance)}</span>
			</li>
			<li>
				Планируемая оплата:
				<span>{changeCurrencyFormat(plannedRepay)}</span>
			</li>
			<li>
				Остаток лимита:
				<span>{changeCurrencyFormat(balanceOfLimit)}</span>
			</li>
		</ul>
	);
};
