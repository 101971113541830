const loc = 'ru-Ru';
const payOptions = { style: 'currency', currency: 'RUB' };

export const changeDateFormat = (date) => {
	return new Intl.DateTimeFormat(loc).format(new Date(date));
};

export const changeCurrencyFormat = (currency) => {
	return new Intl.NumberFormat(loc, payOptions).format(currency);
};
