import axios from 'axios';
import config from '../config/config';
const { ApiUrl } = config;

export default axios.create({
	baseURL: ApiUrl,
});

export const axiosPrivate = axios.create({
	baseURL: ApiUrl,
	headers: { 'Content-Type': 'application/json' },
	withCredentials: true,
});
