import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	Button,
	TextField,
	Checkbox,
	InputLabel,
	TextareaAutosize,
} from '@mui/material';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import config from '../../../config/config';
import { InputWithCategory } from '../../../Components/InputWithCategory/InputWithCategory';
import ErrorMessage from '../../../Components/ErrorMessage/ErrorMessage';

import classes from './AddTransaction.module.css';

const { transactionPrefix, tranchePrefix } = config;

export const AddTransaction = () => {
	const navigate = useNavigate();
	const axiosPrivate = useAxiosPrivate();
	const { id } = useParams();
	const [tranche, setTranche] = useState({
		org: '',
		creditForm: '',
		// inDate: new Date(),
		debt: 0,
		info: '',
	});
	const [checked, setChecked] = useState(false);
	const [transaction, setTransaction] = useState({
		date: new Date(),
		info: '',
	});
	const [message, setMessage] = useState('');
	//сумма запланированных транзакций для проверки на превышение долга
	const [summNotRealTransactions, setSummPlannedRepay] = useState(0);
	//Максимально возможная сумма планируемого погашения
	const maxPossiblePlannedRepay = tranche?.balance - summNotRealTransactions;

	async function createTransaction(event) {
		try {
			event.preventDefault();
			const targetValue = document.getElementById(
				checked ? 'plannedRepay' : 'repay'
			);
			//Проверки
			if (targetValue.value > tranche?.balance) {
				setMessage('Сумма платежа превышает задолженность по выбранной форме');
				return;
			}

			if (checked && targetValue.value > maxPossiblePlannedRepay) {
				//Сумма превышения
				const overValue = targetValue.value - maxPossiblePlannedRepay;
				setMessage(
					'Сумма запланированных платежей превышает задолженность по выбранной форме на ' +
						overValue.toLocaleString('ru-Ru') +
						' ₽!'
				);
				return;
			}

			const form = new FormData(event.currentTarget);
			if (checked) {
				form.append('repay', 0);
			} else {
				form.append('plannedRepay', 0);
				form.append('real', true);
			}
			form.append('trancheId', id);
			const f_data = Object.fromEntries(form.entries());
			// console.log('data', f_data);
			console.log(f_data);
			const response = await axiosPrivate.post(`${transactionPrefix}`, f_data);
			navigate(-1);
			setMessage('');
		} catch (error) {
			setMessage(error?.response?.data?.message);
		}
	}

	useEffect(() => {
		async function getTrancheById() {
			try {
				// Получим данные по траншу
				const { data } = await axiosPrivate.get(`${tranchePrefix}/${id}`);
				setTranche(data);
				// Получим все транзакции
				const transactionAll = await axiosPrivate.get(
					`${transactionPrefix}/all/${id}`
				);
				// Расчитаем сумму запланированных транзакций
				if (transactionAll.data.length > 0) {
					const summPlannedRepay = transactionAll.data
						.filter((trans) => !trans.real)
						.reduce((summ, trans) => summ + trans.plannedRepay, 0);
					setSummPlannedRepay(summPlannedRepay);
				}
			} catch (error) {
				console.error('error: ', error);
			}
		}

		getTrancheById();
	}, []);

	const handleChange = (e) => {
		setTransaction((prev) => ({ ...prev, [e.target.name]: e.target?.value }));
	};

	const handleChangeCheckBox = (event) => {
		setChecked(event.target.checked);
	};

	return (
		<div className={classes.wrapper}>
			<h1>
				{checked
					? 'Добавляем заявку на погашение долга'
					: 'Погашаем долг организации'}
				: {tranche.org.name}
			</h1>

			<h3>Кредит по форме: {tranche.creditForm.name}</h3>
			<h3>Задолженность: {tranche?.balance?.toLocaleString('ru-RU')} ₽</h3>
			<span className={classes.summPlannedRepay}>
				В планах на погашение: {summNotRealTransactions.toLocaleString('ru-RU')}{' '}
				₽
			</span>
			<form onSubmit={createTransaction} className={classes.form}>
				<div className={classes.CheckBoxStyle}>
					<Checkbox
						id="real"
						checked={checked}
						onChange={handleChangeCheckBox}
						inputProps={{ 'aria-label': 'controlled' }}
						size="large"
					/>
					<label htmlFor="real">Планируемое погашение </label>
				</div>
				<div className={classes.inputWrapper}>
					<InputWithCategory name={checked ? 'plannedRepay' : 'repay'} />
					<TextField
						fullWidth
						name="date"
						label="Дата операции"
						variant="outlined"
						onChange={(event) => setTransaction({ date: event.target.value })}
						value={new Date(transaction?.date).toISOString().split('T')[0]}
						autoComplete="off"
						type="date"
					/>
					<TextField
						name="info"
						label="Информация о заявке"
						value={transaction.info}
						onChange={handleChange}
						rows={4}
						multiline
					/>
				</div>
				<ErrorMessage message={message} />
				<Button
					variant="contained"
					color="primary"
					size="large"
					type="submit"
					// onClick={(event) => createorganizations(event)}
				>
					Создать
				</Button>
			</form>
		</div>
	);
};
