import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, TextField } from '@mui/material';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import config from '../../../config/config';
import { InputWithCategory } from '../../../Components/InputWithCategory/InputWithCategory';
import ErrorMessage from '../../../Components/ErrorMessage/ErrorMessage';

import classes from './UpdateTransaction.module.css';

const { transactionPrefix, tranchePrefix } = config;

export const UpdateTransaction = () => {
	const navigate = useNavigate();
	const axiosPrivate = useAxiosPrivate();
	const { trancheId, transacId } = useParams();
	const [tranche, setTranche] = useState({
		org: '',
		creditForm: '',
		debt: 0,
		info: '',
	});
	// const [checked, setChecked] = useState(false);
	const [transaction, setTransaction] = useState({
		date: new Date(),
		// info: '',
		plannedRepay: '',
	});
	const [message, setMessage] = useState('');
	//Разобьем сумму платежа на рубли и копейки
	let [plannedRepayRub, plannedRepayCent] = transaction.plannedRepay
		.toString()
		.split('.');
	//Копейки должны быть двухзначным числом
	if (plannedRepayCent && plannedRepayCent.length < 2) {
		plannedRepayCent += 0;
	}

	async function updateTransaction(event) {
		try {
			event.preventDefault();
			const targetValue = document.getElementById('plannedRepay');
			if (targetValue.value > tranche?.balance) {
				setMessage('Сумма платежа превышает задолженность по выбранной форме');
				return;
			}
			const form = new FormData(event.currentTarget);
			form.append('repay', 0);
			form.append('trancheId', trancheId);
			const f_data = Object.fromEntries(form.entries());
			// console.log('data', f_data);
			// console.log(f_data);
			const response = await axiosPrivate.put(
				`${transactionPrefix}/${transacId}`,
				f_data
			);
			navigate(-1);
			setMessage('');
		} catch (error) {
			setMessage(error?.response?.data?.message);
		}
	}

	useEffect(() => {
		async function getTrancheById() {
			try {
				const { data } = await axiosPrivate.get(
					`${tranchePrefix}/${trancheId}`
				);
				setTranche(data);
			} catch (error) {}
		}
		async function getTransactionById() {
			try {
				const { data } = await axiosPrivate.get(
					`${transactionPrefix}/${transacId}`
				);
				setTransaction({
					plannedRepay: data.plannedRepay,
					date: data.date.split('T')[0],
					// info: data.info
				});
			} catch (error) {}
		}
		getTrancheById();
		getTransactionById();
	}, []);

	const handleChangeData = (e) => {
		setTransaction((prev) => ({ ...prev, date: e.target.value }));
	};

	return (
		<div className={classes.wrapper}>
			<h1>
				Редактировать заявку на погашение долга организации : {tranche.org.name}
			</h1>

			<h3>Кредит по форме: {tranche.creditForm.name}</h3>
			<h3>Задолженность: {tranche?.balance?.toLocaleString('ru-RU')} ₽</h3>
			<form onSubmit={updateTransaction} className={classes.form}>
				{/* <div className={classes.CheckBoxStyle}>
					<Checkbox
						id="real"
						checked={checked}
						onChange={handleChangeCheckBox}
						inputProps={{ 'aria-label': 'controlled' }}
						size="large"
					/>
					<label htmlFor="real">Планируемое погашение </label>
				</div> */}
				<div className={classes.inputWrapper}>
					<InputWithCategory
						name={'plannedRepay'}
						startPayment={plannedRepayRub ?? '0'}
						startCent={plannedRepayCent ?? '00'}
					/>
					<TextField
						fullWidth
						name="date"
						label="Дата операции"
						variant="outlined"
						onChange={handleChangeData}
						value={transaction.date}
						autoComplete="off"
						type="date"
					/>
					{/* <TextField
						name="info"
						label="Информация о заявке"
						value={transaction.info}
						onChange={handleChangeInfo}
						rows={4}
						multiline
					/> */}
				</div>
				<ErrorMessage message={message} />
				<Button variant="contained" color="primary" size="large" type="submit">
					Принять
				</Button>
			</form>
		</div>
	);
};
