import { Outlet } from 'react-router-dom';
import TopBar from '../../Components/TopBar/TopBar';
import Sidebar from '../../Components/Sidebar/Sidebar';

import classes from './Layout.module.css';

const Layout = () => {
	return (
		<>
			<TopBar />
			<div className={classes.wrapper}>
				<Sidebar />
				<Outlet />
			</div>
		</>
	);
};
export default Layout;
