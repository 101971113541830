export const filterReducer = (state, { type, payload }) => {
	// debugger;
	switch (type) {
		//Первичный рендер компоненты
		case 'Initial':
			return payload.newItems;
		//если на входе один элемент
		case 'One':
			const newItem = payload.newItems[0];
			// Удаляем элемент если он был в стейте, если не было - добавим
			if (state.includes(newItem)) {
				return state.filter((item) => item !== newItem);
			} else {
				return [...state, newItem];
			}
		//Если на входе все элементы
		case 'All':
			//Если повторно приходят все эелементы - очищаем стейт
			if (state.length === payload.newItems.length) {
				return [];
			} else {
				return payload.newItems;
			}

		default:
			return state;
	}
};

export const addNewFilterItems = (newItems) => {
	const type = newItems.length === 1 ? 'One' : 'All';

	return {
		type,
		payload: { newItems },
	};
};

export const addInitialFilterItems = (newItems) => {
	return {
		type: 'Initial',
		payload: { newItems },
	};
};
