import PropTypes from 'prop-types';
import styles from './filterList.module.css';
import clsx from 'clsx';

export const FilterList = ({ dataItems, currentItems, choiceItems }) => {
	// console.log('выбранные', currentItems);
	dataItems = dataItems.sort();
	//Проверка все ли выбранны
	const isAllCurrent = dataItems.length === currentItems.length;

	const renderItems = dataItems.map((item, i) => {
		const isCurrentItem = currentItems.includes(item);

		return (
			<li
				onClick={() => choiceItems([item])}
				className={clsx(styles.item, isCurrentItem && styles.currentItem)}
				key={i}
			>
				{item}
			</li>
		);
	});

	//Добавим элемент 'Все'
	renderItems.unshift(
		<li
			onClick={() => choiceItems(dataItems)}
			//Применяем стиль <выбранного> если выбранны все элементы
			className={clsx(styles.item, isAllCurrent && styles.currentItem)}
			key={new Date()}
		>
			Все
		</li>
	);
	return <ul className={styles.itemsList}>{renderItems}</ul>;
};

FilterList.propTypes = {
	dataItems: PropTypes.arrayOf(PropTypes.string),
	setItems: PropTypes.func,
	currentItems: PropTypes.arrayOf(PropTypes.string),
};
