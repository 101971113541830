import { useState, useRef } from 'react';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import { Box, Select, MenuItem, InputLabel, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { InputWithCategory } from '../InputWithCategory/InputWithCategory';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { formatDate } from '../../utils/formatDate';

export const ButtonPopover = ({ title, orgList, getData }) => {
	//Отфильтруем организации у которых нет долгов
	orgList = orgList?.filter((org) => org.totalRepay > 0);
	const axiosPrivate = useAxiosPrivate();
	const buttonRef = useRef(null);
	const [isOpen, setIsOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [org, setOrg] = useState('');

	const handleClick = () => {
		setErrorMessage('');
		setIsOpen(!isOpen);
	};

	const id = 'simple-popper';

	async function handleSubmit(event) {
		try {
			event.preventDefault();

			const form = new FormData(event.currentTarget);
			// form.append('orgId', org);
			const f_data = Object.fromEntries(form.entries());
			//Находим данные по выбранной организации
			const selectedOrg = orgList.find((org) => org._id === f_data.orgId);
			//Валидация дата погашения - не может быть раньше выдачи первого транша
			if (
				selectedOrg &&
				Date.parse(f_data.date) < Date.parse(selectedOrg.firstTrancheData)
			) {
				setErrorMessage(
					`Дата погашения не может быть раньше выдачи первого транша (${formatDate(
						new Date(selectedOrg.firstTrancheData)
					)})`
				);

				return;
			}
			const response = await axiosPrivate.post('/tranche/pod', f_data);
			setIsOpen(false);
			setErrorMessage('');
			getData();
		} catch (error) {
			console.log(error);
		}
	}

	const handleChange = (e) => {
		setOrg(e.target?.value);
	};

	return (
		<>
			<Button
				aria-describedby={id}
				variant="contained"
				size="small"
				color={title === 'Погасить' ? 'warning' : 'primary'}
				onClick={handleClick}
				ref={buttonRef}
			>
				{title}
			</Button>

			<Popper id={id} open={isOpen} anchorEl={buttonRef.current}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						border: 1,
						p: 2,
						bgcolor: 'background.paper',
						borderRadius: 2,
						mt: 1,
					}}
				>
					<Typography>{title}</Typography>
					<form onSubmit={handleSubmit}>
						<div style={{ marginBottom: 8 }}>
							<InputLabel id="org">Организация</InputLabel>
							<Select
								labelId="org"
								id="org"
								sx={{ width: '100%' }}
								value={org}
								name="orgId"
								// label="org"
								onChange={handleChange}
								required
							>
								{orgList?.map((item) => (
									<MenuItem key={item?._id} value={item?._id} name={item?.name}>
										{item?.name}
									</MenuItem>
								))}
							</Select>
						</div>
						<InputWithCategory name="pay" />
						<div style={{ marginTop: '12px' }}>
							<TextField
								fullWidth
								name="date"
								label="Дата операции"
								variant="outlined"
								// onChange={handleChange}
								defaultValue={new Date().toISOString().split('T')[0]}
								autoComplete="off"
								type="date"
							/>
						</div>
						<Box
							sx={{
								marginTop: 2,
								display: 'flex',
								justifyContent: 'center',
								gap: 1,
							}}
						>
							<Button
								variant="outlined"
								color="error"
								onClick={() => handleClick()}
							>
								Отмена
							</Button>
							<Button variant="outlined" color="success" type="submit">
								Подтвердить
							</Button>
						</Box>
						<div
							style={{
								maxWidth: '400px',
								textAlign: 'center',
								marginTop: '10px',
							}}
						>
							{errorMessage && <ErrorMessage message={errorMessage} />}
						</div>
					</form>
				</Box>
			</Popper>
		</>
	);
};
