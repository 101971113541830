import './userList.css';
import { useState, useEffect } from 'react';
import renderCellExpand from '../../../Components/GridCellExpand/GridCellExpand';
import { PinkSwitch } from '../../../config/some';
import CustomDataGrid from '../../../Components/CustomDataGrid/CustomDataGrid';
import CircleButton from '../../../Components/CustomButtons/CircleButton';
import DeleteDialog from '../../../Components/Dialog/DeleteDialog';
import EditButton from '../../../Components/CustomButtons/EditButton';
import DeleteButton from '../../../Components/CustomButtons/DeleteButton';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import config from '../../../config/config';
import ChangeButton from '../../../Components/CustomButtons/ChangeButton';

import { ChangePasswordDialog } from '../../../Components/Dialog/ChangePasswordDialog.jsx';

const UserList = () => {
	const axiosPrivate = useAxiosPrivate();
	const [users, setUsers] = useState([]);
	const [open, setOpen] = useState(false);
	const [passwordOpen, setPasswordOpen] = useState(false);
	const [changePasswordInfo, setChangePasswordInfo] = useState({
		id: '',
		email: '',
		name: '',
	});

	const [idDelete, setIdDelete] = useState('');

	const [messages, setMessages] = useState('');
	const [loading, setLoading] = useState(false);

	const label = { inputProps: { 'aria-label': 'active zakaz' } };

	const getUsers = async () => {
		try {
			setMessages('');
			setLoading(true);
			const response = await axiosPrivate.get(`/${config?.adminPrefix}`);
			setUsers(response.data);
			setLoading(false);
		} catch (error) {
			setMessages(error?.response?.data?.message);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getUsers();
	}, []);

	const handleChangeEnabled = async (event, id) => {
		try {
			setMessages('');
			const state = event.target?.checked;
			const response = await axiosPrivate.post(`/users/state/${id}`, {
				state,
			});
			//mutate user state
			const itemIndex = users.findIndex(
				(item) => item._id === response.data._id
			);
			const updateItems = [...users];
			updateItems[itemIndex] = {
				...updateItems[itemIndex],
				enabled: response.data.enabled,
			};
			setUsers(updateItems);
		} catch (error) {
			setMessages(error?.response?.data?.message);
		}
	};

	const prepareDelete = async (id, name) => {
		setIdDelete({ id, name });
		setOpen(true);
	};

	const handleDelete = () => {
		userDelete(idDelete?.id);
		setOpen(false);
	};

	const handleCloseChangePassword = () => {
		setPasswordOpen(false);
	};

	async function prepareChangePassword(id, email, name) {
		setChangePasswordInfo({
			id,
			email,
			name,
		});
		setPasswordOpen(true);
	}

	async function userDelete(id) {
		try {
			setMessages('');
			const response = await axiosPrivate.post(
				`/${config?.adminPrefix}/delete/${id}`
			);
			getUsers();
		} catch (error) {
			setMessages(error?.response?.data?.message);
		}
	}

	const changePassword = async (id, password) => {
		try {
			if (!id || !password) {
				return null;
			}
			setMessages('');
			const f_data = new FormData();
			f_data.append('password', password);
			const response = await axiosPrivate.post(
				`/${config?.adminPrefix}/update_password/${id}`,
				f_data
			);
			handleCloseChangePassword();
		} catch (error) {
			setMessages(error?.response?.data?.message);
		}
	};

	const columns = [
		{
			field: 'name',
			headerName: 'ФИО',
			width: 200,
			editable: false,
			renderCell: renderCellExpand,
		},
		{
			field: 'email',
			headerName: 'Email',
			width: 200,
			editable: false,
			renderCell: renderCellExpand,
		},
		{
			field: 'position',
			headerName: 'Должность',
			width: 200,
			editable: false,
			renderCell: renderCellExpand,
		},
		{
			field: 'enabled',
			headerName: 'Активность',
			type: 'boolean',
			with: 150,
			editable: false,
			disableColumnMenu: true,
			headerAlign: 'center',
			renderCell: (params) => {
				return (
					<div className="styleButton">
						<PinkSwitch
							{...label}
							checked={params?.row?.enabled}
							onChange={(event) => handleChangeEnabled(event, params.row._id)}
						/>
					</div>
				);
			},
		},
		{
			field: 'action',
			headerName: 'Редактировать',
			width: 130,
			disableColumnMenu: true,
			sortable: false,
			headerAlign: 'center',
			renderCell: (params) => {
				return <EditButton link={`/user/${params.row._id}`} />;
			},
		},
		{
			field: 'action2',
			headerName: 'Удалить',
			width: 100,
			disableColumnMenu: true,
			sortable: false,
			headerAlign: 'center',
			renderCell: (params) => {
				return (
					<DeleteButton
						handleClick={() =>
							prepareDelete(params?.row?._id, params?.row?.name)
						}
					/>
				);
			},
		},
		{
			field: 'action3',
			headerName: 'Пароль',
			width: 90,
			disableColumnMenu: true,
			sortable: false,
			headerAlign: 'center',
			renderCell: (params) => {
				return (
					<ChangeButton
						handleClick={() =>
							prepareChangePassword(
								params?.row._id,
								params?.row.email,
								params?.row.name
							)
						}
					/>
				);
			},
		},
	];

	return (
		<>
			<DeleteDialog
				textDialog={`Внимание! Вы собираетесь удалить пользователя ${idDelete?.name}.`}
				handle={handleDelete}
				open={open}
				setOpen={setOpen}
			/>
			<ChangePasswordDialog
				isOpen={passwordOpen}
				handleClose={handleCloseChangePassword}
				changePassword={changePassword}
				changePasswordInfo={changePasswordInfo}
			/>
			<div className="userList">
				<h1 className="newUserTitle">Пользователи</h1>
				<div className="messages">{messages}</div>
				<CircleButton link={'/newUser'} />
				<CustomDataGrid rows={users} columns={columns} isLoading={loading} />
			</div>
		</>
	);
};

export default UserList;
