import $api from '../http';
import useAuth from './useAuth';
import config from '../config/config';
const { RefreshUrl } = config;

const useRefreshToken = () => {
	const { setAuth } = useAuth();

	const refresh = async () => {
		const response = await $api.get(RefreshUrl);
		setAuth(true);
		return response;
	};
	return refresh;
};

export default useRefreshToken;
