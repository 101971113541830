import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './user.css';
import config from '../../../config/config';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { Button, TextField } from '@mui/material';
import ErrorMessage from '../../../Components/ErrorMessage/ErrorMessage';

const User = () => {
	const { id } = useParams();
	const axiosPrivate = useAxiosPrivate();
	const [user, setUser] = useState({
		name: '',
		email: '',
		position: '',
		password: '',
		enabled: false,
	});
	const [message, setMessage] = useState('');
	let navigate = useNavigate();

	const handleChange = (e) => {
		setUser((prev) => ({ ...prev, [e.target.name]: e.target?.value }));
	};

	useEffect(() => {
		getItemUsers(id);
	}, [id]);

	async function getItemUsers(id) {
		try {
			setMessage('');
			const response = await axiosPrivate.get(`/${config?.adminPrefix}/${id}`);
			console.log(response.data);
			setUser(response.data);
		} catch (error) {
			setMessage(error?.response?.data?.message);
		}
	}

	async function updateUserAdm(event) {
		try {
			event.preventDefault();
			const f_data = new FormData();
			f_data.append('name', user?.name);
			f_data.append('email', user?.email);
			f_data.append('position', user?.position);
			f_data.append('password', user?.password);
			f_data.append('enabled', user?.enabled);
			const response = await axiosPrivate.post(
				`/${config?.adminPrefix}/update/${id}`,
				f_data
			);
			navigate('/users');
			setMessage('');
		} catch (error) {
			setMessage(error?.response?.data?.message);
		}
	}
	return (
		<>
			<div className="user">
				<div className="userTitleContainer">
					<h1 className="userTitle">Редактировать пользователя(АДМ)</h1>
				</div>
				<ErrorMessage message={message} />
				<div className="userContainer">
					<div className="userUpdate">
						<span className="userUpdateTitle">Изменить</span>
						<div className="userUpdateForm">
							<div className="userUpdateLeft">
								<div className="userUpdateItem">
									<TextField
										fullWidth
										label="ФИО"
										name="name"
										variant="outlined"
										onChange={handleChange}
										value={user?.name}
										autoComplete="off"
									/>
								</div>
								<div className="userUpdateItem">
									<TextField
										fullWidth
										label="Email"
										name="email"
										variant="outlined"
										onChange={handleChange}
										value={user?.email}
										autoComplete="off"
									/>
								</div>
								<div className="userUpdateItem">
									<TextField
										fullWidth
										label="Должность"
										name="position"
										variant="outlined"
										onChange={handleChange}
										value={user?.position}
										autoComplete="off"
									/>
								</div>
								<Button
									variant="contained"
									color="primary"
									size="large"
									onClick={(event) => updateUserAdm(event)}
								>
									Сохранить изменения
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default User;
