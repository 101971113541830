import clsx from 'clsx';
import styles from './showRestLimit.module.css';

export const ShowRestLimit = ({ filterOrgName, restOrgLimit, className }) => {
	const text =
		filterOrgName.length === 0 ? 'Выберите компанию' : 'Остаток лимита для ';
	return (
		<div className={clsx(styles.wrapper, className)}>
			<span className={styles.restText}>
				{text}
				{filterOrgName.join(', ')}
			</span>
			<h2
				className={clsx(
					styles.restOrgLimit,
					restOrgLimit >= 0
						? styles.restLimitPositive
						: styles.restLimitNegative
				)}
			>
				{restOrgLimit.toLocaleString('ru-Ru')} ₽
			</h2>
		</div>
	);
};
