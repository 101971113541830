import { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import config from '../../config/config';

const { transactionPrefix } = config;

export const RepaymentDialog = ({
	open,
	setOpen,
	repayment,
	id,
	trancheId,
	getTransactionsById,
	getTrancheById,
}) => {
	const axiosPrivate = useAxiosPrivate();
	const handleClose = () => {
		setOpen(false);
	};
	const handleSubmit = async () => {
		try {
			if (repayment <= 0) return;
			const data = {
				trancheId: trancheId,
				repay: repayment,
				real: true,
				plannedRepay: 0,
			};
			const response = await axiosPrivate.put(
				`${transactionPrefix}/${id}`,
				data
			);
			handleClose();
			getTransactionsById();
			getTrancheById();
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				Вы уверены что хотите провести заявку на сумму{' '}
				{repayment.toLocaleString('ru-RU')} ₽ ?
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					Осуществляя данное действие вы подтверждаете свершение оплаты по
					текущему виду долгового обязательства
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Отменить</Button>
				<Button onClick={handleSubmit} autoFocus>
					Подтвердить
				</Button>
			</DialogActions>
		</Dialog>
	);
};
