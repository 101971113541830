import { useState, useEffect } from 'react';
import CustomDataGrid from '../../Components/CustomDataGrid/CustomDataGrid';
import CircleButton from '../../Components/CustomButtons/CircleButton';
import config from '../../config/config';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import EditButton from '../../Components/CustomButtons/EditButton';

import classes from './CreditFormList.module.css';

const { creditFormPrefix } = config;

export const CreditFormList = () => {
	const [creditFormList, setCreditFormList] = useState([]);
	const axiosPrivate = useAxiosPrivate();
	useEffect(() => {
		async function getCreditForms() {
			try {
				const { data } = await axiosPrivate.get(creditFormPrefix);
				setCreditFormList(data);
			} catch (error) {
				console.log(error);
			}
		}
		getCreditForms();
	}, []);

	const columns = [
		{
			field: 'name',
			headerName: 'Название',
			width: 200,
			editable: false,
		},

		// {
		// 	field: 'time',
		// 	headerName: 'Срок погашения',
		// 	width: 200,
		// 	editable: false,
		// },

		{
			field: 'action',
			headerName: 'Редактировать',
			width: 150,
			headerAlign: 'center',
			align: 'center',
			cellClassName: 'actionStyle',
			disableColumnMenu: true,
			sortable: false,
			renderCell: (params) => {
				return <EditButton link={'/creditFormList/' + params?.row._id} />;
			},
		},
	];
	return (
		<main className={classes.wrapper}>
			<h1>Формы долга</h1>
			<CircleButton link={'/creditFormList/addCreditForm'} />
			<CustomDataGrid rows={creditFormList} columns={columns} />
		</main>
	);
};
