const config = {
	/** server */
	ApiUrl: 'https://registryapi.copartner.ru/api',
	UPLOAD_API_URL: 'https://registryapi.copartner.ru',

	/**local*/
	// ApiUrl: 'http://localhost:7500/api',
	// UPLOAD_API_URL: 'http://localhost:7500',

	MAX_FILE: 10,
	RefreshUrl: 'users/refresh',
	organizationPrefix: '/organization',
	tranchePrefix: '/tranche',
	creditFormPrefix: 'credit-form',
	adminPrefix: 'users',
	userPrefix: 'users_amp',
	transactionPrefix: '/transaction',
};

export default config;
