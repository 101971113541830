import { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { PortalNote } from '../PortalNote/PortalNote';
import { Box, Portal } from '@mui/material';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import config from '../../config/config';

export const InfoDialog = ({ info, id, open, setOpen, getData, URL }) => {
	const [note, setNote] = useState('');
	const [show, setShow] = useState(false);
	const axiosPrivate = useAxiosPrivate();
	const container = useRef(null);
	const handleClose = () => {
		setOpen(false);
	};

	const handleChangeNote = (event) => {
		setNote(event.target.value);
	};

	const handleClick = () => {
		setShow(!show);
	};

	const handleSaveNote = async () => {
		if (note) {
			try {
				const data = {
					info: note.trim(),
				};
				const response = await axiosPrivate.put(`${URL}/${id}`, data);
				handleClose();
				setNote('');
				getData();
			} catch (err) {
				console.error(err);
				// navigate('/login', { state: { from: location }, replace: true });
			}
		}
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="responsive-dialog-title"
		>
			<DialogTitle id="responsive-dialog-title">Примечание</DialogTitle>
			<DialogContent>
				<ul>
					{info.map((el, i) => (
						<li key={i}>
							<DialogContentText sx={{ color: 'black' }}>
								{el}
							</DialogContentText>
						</li>
					))}
				</ul>
			</DialogContent>
			<Box sx={{ p: 1 }}>
				<Button
					startIcon={<NoteAltIcon />}
					variant="contained"
					onClick={handleClick}
				>
					Примечание
				</Button>

				{show ? (
					<Portal container={container.current}>
						<PortalNote
							note={note}
							onChange={handleChangeNote}
							onClick={handleSaveNote}
						/>
					</Portal>
				) : null}
				<Box ref={container} />
			</Box>
		</Dialog>
	);
};
