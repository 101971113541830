import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import config from '../../config/config';
import CircleButton from '../../Components/CustomButtons/CircleButton';
import CustomDataGrid from '../../Components/CustomDataGrid/CustomDataGrid';
import { InfoDialog } from '../../Components/Dialog/InfoDialog';
import InfoIcon from '@mui/icons-material/Info';
import AddTaskIcon from '@mui/icons-material/AddTask';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { RepaymentDialog } from '../../Components/Dialog/RepaymentDialog';

import classes from './Transactions.module.css';
import DeleteDialog from '../../Components/Dialog/DeleteDialog';

const { tranchePrefix, transactionPrefix } = config;

export const Transactions = () => {
	const navigate = useNavigate();
	const axiosPrivate = useAxiosPrivate();
	const { id } = useParams();
	const [tranche, setTranche] = useState({
		org: '',
		creditForm: '',
		// inDate: new Date(),
		debt: 0,
	});

	const loc = 'ru-Ru';
	const payOptions = { style: 'currency', currency: 'RUB' };

	const [currentTransaction, setCurrentTransaction] = useState({
		info: [],
		id: '',
	}); // стейт примечения и id выбранной транзакции
	const [openInfo, setOpenInfo] = useState(false); // модульное окно для примечания

	//---RepaymentDialog
	const [openRepayment, setOpenRepayment] = useState(false);
	// стейт примечения и id выбранной транзакции
	const [repayment, setRepayment] = useState({
		repayment: 0,
		id: '',
	});

	//---DeleteDialog
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [forDeleteData, setForDeleteData] = useState({
		repayment: 0,
		id: '',
	});
	const handleDeleteDialog = async () => {
		await axiosPrivate.delete(`${transactionPrefix}/${forDeleteData.id}`);
		await getTransactionsById();
		setOpenDeleteDialog(false);
	};

	// ---список транзакций данного транша
	const [transactionsList, setTransactionsList] = useState([]);
	// const transactionsSum = transactionsList?.length
	// 	? transactionsList?.reduce(
	// 			(accumulator, currentValue) => accumulator + currentValue.repay,
	// 			0.0
	// 	  )
	// 	: 0;
	// // console.log(transactionsList);
	const [loading, setLoading] = useState(false);

	//коллбэки для кнопок
	const preparedCheckInfo = (info, id) => {
		setCurrentTransaction({ info, id });
		setOpenInfo(true);
	};

	const preparedRepaymentTransaction = (repayment, id) => {
		setRepayment({ repayment, id });
		setOpenRepayment(true);
	};

	const preparedDeleteTransaction = (repayment, id) => {
		setForDeleteData({ repayment, id });
		setOpenDeleteDialog(true);
	};

	//запросы
	async function getTransactionsById() {
		try {
			setLoading(true);
			const { data } = await axiosPrivate.get(`${transactionPrefix}/all/${id}`);
			setLoading(false);
			setTransactionsList(data);
		} catch (error) {}
	}

	async function getTrancheById() {
		try {
			const { data } = await axiosPrivate.get(`${tranchePrefix}/${id}`);
			setTranche(data);
		} catch (error) {}
	}
	useEffect(() => {
		getTrancheById();
		getTransactionsById();
	}, []);

	//колонки для грида
	const columns = [
		{
			field: 'real',
			headerName: 'Фактическая оплата',
			type: 'boolean',
			with: 50,
			editable: false,
			disableColumnMenu: true,
			headerAlign: 'center',
		},
		{
			field: 'repay',
			headerName: 'сумма (факт)',
			width: 150,
			editable: false,
			renderCell: (params) => {
				return new Intl.NumberFormat(loc, payOptions).format(params.value);
			},
		},
		{
			field: 'plannedRepay',
			headerName: 'сумма (план)',
			width: 150,
			editable: false,
			renderCell: (params) => {
				return new Intl.NumberFormat(loc, payOptions).format(params.value);
			},
		},

		{
			field: 'date',
			headerName: 'дата операции',
			width: 100,
			editable: false,
			renderCell: (params) => {
				return new Intl.DateTimeFormat(loc).format(new Date(params?.value));
			},
		},

		{
			field: 'action',
			headerName: 'Инфо',
			width: 130,
			disableColumnMenu: true,
			sortable: false,
			headerAlign: 'center',
			renderCell: (params) => {
				return (
					<Button
						startIcon={<InfoIcon />}
						variant="contained"
						onClick={() =>
							preparedCheckInfo(params?.row?.info, params?.row?._id)
						}
					>
						инфо
					</Button>
				);
			},
		},
		{
			field: 'action2',
			headerName: 'Действие',
			width: 150,
			disableColumnMenu: true,
			sortable: false,
			headerAlign: 'center',
			renderCell: (params) => {
				return (
					<Button
						startIcon={<AddTaskIcon />}
						variant="contained"
						color="success"
						onClick={() =>
							preparedRepaymentTransaction(
								params?.row?.plannedRepay,
								params?.row?._id
							)
						}
						disabled={params?.row?.real}
					>
						Провести
					</Button>
				);
			},
		},
		{
			field: 'action3',
			headerName: 'Редактировать',
			width: 200,
			disableColumnMenu: true,
			sortable: false,
			headerAlign: 'center',
			renderCell: (params) => {
				return (
					<Button
						startIcon={<EditIcon />}
						variant="contained"
						color="info"
						onClick={() =>
							navigate(
								`/transactions/updateTransaction/${params?.row?._id}/tranche/${params?.row?.trancheId}`
							)
						}
						disabled={params?.row?.real}
					>
						Редактировать
					</Button>
				);
			},
		},
		{
			field: 'action4',
			headerName: 'Удалить',
			width: 150,
			disableColumnMenu: true,
			sortable: false,
			headerAlign: 'center',
			renderCell: (params) => {
				return (
					<Button
						startIcon={<DeleteOutlineOutlinedIcon />}
						variant="contained"
						color="error"
						onClick={() =>
							preparedDeleteTransaction(
								params?.row?.plannedRepay,
								params?.row?._id
							)
						}
						disabled={params?.row?.real}
					>
						Удалить
					</Button>
				);
			},
		},
	];

	//resize columns by windows width
	if (window.innerWidth <= 1280) {
		columns.forEach((item) => {
			item.width = item.width * 0.9;
		});
	}

	return (
		<>
			<InfoDialog
				open={openInfo}
				setOpen={setOpenInfo}
				info={currentTransaction.info}
				getData={getTransactionsById}
				id={currentTransaction.id}
				URL={transactionPrefix}
			/>
			<RepaymentDialog
				open={openRepayment}
				setOpen={setOpenRepayment}
				repayment={repayment.repayment}
				id={repayment.id}
				trancheId={id}
				getTransactionsById={getTransactionsById}
				getTrancheById={getTrancheById}
			/>
			<DeleteDialog
				textDialog={`Внимание! Вы собираетесь удалить заявку на погашение ${forDeleteData.repayment.toLocaleString(
					loc
				)} ₽`}
				handle={handleDeleteDialog}
				open={openDeleteDialog}
				setOpen={setOpenDeleteDialog}
			/>
			<div className={classes.wrapper}>
				<h1>Долговое обязательство {tranche.org.name}</h1>
				<h3>По форме: {tranche.creditForm.name}</h3>
				<h3>Задолженность: {tranche?.balance?.toLocaleString('ru-RU')} ₽</h3>
				<div className={classes.buttonsGroup}>
					<Button
						variant="contained"
						color="primary"
						onClick={() => navigate(-1)}
						sx={{ height: '60%' }}
					>
						Назад
					</Button>
					<CircleButton link={`/transactions/createTransaction/${id}`} />
				</div>
				<CustomDataGrid
					rows={transactionsList}
					columns={columns}
					isLoading={loading}
					getRowClassName={(params) =>
						`super-transactions-details-theme--${params?.row?.real}`
					}
					className={classes.table}
				/>
			</div>
		</>
	);
};
