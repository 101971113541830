export const addFirstTrancheData = (orgList, tranchesList) => {
	let result = orgList;

	if (orgList.length > 0) {
		result = orgList.map((org) => {
			const tranchesByOrg = tranchesList.filter(
				(tranche) => tranche.orgName === org.name
			);
			//берем первый элемент, т.к. tranchesList уже отсортирован по дате
			const firstTrancheData = tranchesByOrg[0]?.date ?? null;
			return { ...org, firstTrancheData };
		});
	}
	return result;
};
