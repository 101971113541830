import { TableItem } from './TableItem/TableItem';
import { tableHeader } from '../../utils/tableHeader';
import styles from './CustomTable.module.css';

export const CustomTable = ({ data, totalLimit }) => {
	let limit = totalLimit;
	const sortedData = data
		.sort((a, b) => {
			return (
				new Date(a.returnTranche).getTime() -
				new Date(b.returnTranche).getTime()
			);
		})
		.map((el) => {
			limit = parseFloat((limit - el.balance).toFixed(2));
			return {
				...el,
				excess: limit,
			};
		});

	return (
		<table
			border="3"
			cellPadding="10"
			className={styles.table}
			style={{ borderCollapse: 'collapse' }}
		>
			{/* <caption>Отчеты</caption> */}
			<thead>
				<tr>
					{tableHeader.map((el, i) => {
						const widthCell = el === 'Срок погашения' ? '90px' : 'auto';
						return (
							// <th key={i} style={{ maxWidth: widthCell }}>
							<th key={i}>{el}</th>
						);
					})}
				</tr>
			</thead>
			{/* <tfoot>
				<tr>
					<td colspan="6">Итого</td>
					<td>118</td>
				</tr>
			</tfoot> */}
			<tbody>
				{sortedData?.map((el) => (
					<TableItem
						name={el.org?.name}
						date={el.date}
						creditForm={el.creditForm.name}
						balance={el.balance} //текущий долг
						trancheDay={el.trancheDay}
						returnTranche={el.returnTranche}
						transaction={el.transaction}
						excess={el.excess} //остаток
						//Планируемый остаток долга без учета просроченных запланированных транзакций
						restPlannedDebt={el.restPlannedDebt}
						//Планируемый остаток долга c учетом просроченных запланированных транзакций
						restPlannedDebtWithExpiredTransactions={
							el.restPlannedDebtWithExpiredTransactions
						}
						id={el._id}
						key={el._id}
					/>
				))}
			</tbody>
		</table>
	);
};
