import React from 'react';

const ErrorMessage = ({ message }) => {
	return (
		<div
			style={{
				fontSize: '1.5rem',
				fontWeight: 500,
				color: 'red',
				maxWidth: '700px',
			}}
		>
			{message}
		</div>
	);
};

export default ErrorMessage;
