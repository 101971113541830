import { useState, useEffect } from 'react';
import { Box, TextField } from '@mui/material';
import { formatInputValue } from '../../utils/formatInputValue';

export const InputWithCategory = ({ name, startPayment, startCent }) => {
	const [payment, setPayment] = useState('');
	const [cent, setCent] = useState('');
	const [currentPayment, setCurrentPayment] = useState(0);
	const [message, setMessage] = useState('');
	useEffect(() => {
		if (startPayment || startCent) {
			const currentPayment = formatInputValue(startPayment);
			const currentCent = startCent.replace(/[^0-9\.]/g, '');
			setPayment(currentPayment);
			setCent(currentCent);
			setCurrentPayment(Number(startPayment) + Number(startCent) / 100);
		}
	}, [startPayment, startCent]);

	const handleChangeInput = (event) => {
		const { value } = event.target;
		const curr = formatInputValue(value);
		setPayment(curr);
		let currentPay = Number(curr.split(' ').join('')) + Number(cent) / 100;
		setCurrentPayment(currentPay);
	};
	const handleChangeCent = (event) => {
		const { value } = event.target;
		const current = value.replace(/[^0-9\.]/g, '');
		setCent(current);
		let currentPay =
			Number(payment.split(' ').join('')) + Number(current) / 100;
		setCurrentPayment(currentPay);
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: 1,
				textAlign: 'center',
			}}
		>
			<Box sx={{ display: 'flex', gap: 1 }}>
				<input type="hidden" value={currentPayment} name={name} id={name} />
				<TextField
					fullWidth
					variant="outlined"
					autoComplete="off"
					label="Рубли"
					type="text"
					value={payment}
					onChange={handleChangeInput}
					required
				/>
				<TextField
					fullWidth
					variant="outlined"
					autoComplete="off"
					type="text"
					label="Копейки"
					value={cent}
					sx={{ width: 150 }}
					onChange={handleChangeCent}
					inputProps={{ maxLength: 2 }}
				/>
			</Box>

			{!!message && <p style={{ color: 'red' }}>{message}</p>}
		</Box>
	);
};
