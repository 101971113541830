import { Outlet, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useRefreshToken from '../hooks/useRefreshToken';
import useAuth from '../hooks/useAuth';

const PersistLogin = () => {
	const [isLoading, setIsLoading] = useState(true);
	const refresh = useRefreshToken();
	const { auth } = useAuth();
	const navigate = useNavigate();
	useEffect(() => {
		let isMounted = true;

		const verifyRefreshToken = async () => {
			try {
				await refresh();
			} catch (err) {
				console.log(err);
				navigate('/login');
			} finally {
				isMounted && setIsLoading(false);
			}
		};
		!auth ? verifyRefreshToken() : setIsLoading(false);
		return () => (isMounted = false);
	}, [auth]);
	return <>{isLoading ? <p>Loading...</p> : <Outlet />}</>;
};

export default PersistLogin;
