import { DataGrid, ruRU } from '@mui/x-data-grid';

const CustomDataGrid = ({ rows, columns, isLoading, ...params }) => {
	return (
		<div style={{ height: 650, width: '100%' }}>
			<DataGrid
				localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
				loading={isLoading}
				getRowId={(row) => row._id}
				rows={rows}
				columns={columns}
				pageSize={10}
				rowsPerPageOptions={[10]}
				disableSelectionOnClick
				{...params}
			/>
		</div>
	);
};

export default CustomDataGrid;
