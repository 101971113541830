import { useNavigate, Link } from 'react-router-dom';
import './topBar.css';
import Button from '@mui/material/Button';
import $api from '../../http';
import useAuth from '../../hooks/useAuth';

export default function TopBar() {
	const { auth, setAuth } = useAuth();
	// const auth = false;
	const navigate = useNavigate();

	const handleLogout = async () => {
		await $api.post('/users/logout');
		setAuth(false);
		navigate('/');
	};

	const handleLogin = async () => {
		navigate('/login');
		// navigate('/');
	};
	return (
		<div className="topBar">
			<div className="topBarWrapper">
				<div className="topLeft">
					<Link
						to="/"
						style={{ textDecoration: 'none', textTransform: 'uppercase' }}
					>
						<span className="logo">Расчет лимитов</span>
					</Link>
				</div>

				<div className="topRight">
					{auth ? (
						<Button variant="contained" color="success" onClick={handleLogout}>
							Выход
						</Button>
					) : (
						<Button variant="contained" color="success" onClick={handleLogin}>
							Вход
						</Button>
					)}
				</div>
			</div>
		</div>
	);
}
