import { Link } from 'react-router-dom';
import CalculateIcon from '@mui/icons-material/Calculate';
import './button.css';

const OperationButton = ({ link, handleClick }) => {
	return (
		<div className="styleButton">
			{link ? (
				<Link to={`${link}`}>
					<button>
						<CalculateIcon
							fontSize="large"
							style={{ color: 'rgb(39, 12, 167)', cursor: 'pointer' }}
						/>
					</button>
				</Link>
			) : (
				<button onClick={handleClick}>
					<CalculateIcon
						fontSize="large"
						style={{ color: 'rgb(39, 12, 167)', cursor: 'pointer' }}
					/>
				</button>
			)}
		</div>
	);
};

export default OperationButton;
