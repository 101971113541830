import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, TextField, Select, MenuItem, InputLabel } from '@mui/material';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import config from '../../../config/config';
import { InputWithCategory } from '../../../Components/InputWithCategory/InputWithCategory';
import {
	calculateNearestPreviousWeekday,
	addDays,
} from '../../../utils/calculateDays';
import ErrorMessage from '../../../Components/ErrorMessage/ErrorMessage';
import classes from './EditTranche.module.css';

const { tranchePrefix, organizationPrefix, creditFormPrefix } = config;

export const EditTranche = () => {
	const navigate = useNavigate();
	const axiosPrivate = useAxiosPrivate();
	const { id } = useParams();
	const [tranche, setTranche] = useState({
		org: '',
		creditForm: '',
		date: new Date(),
		trancheDay: 90,
		debt: 0,
	});

	const loc = 'ru-Ru';
	const payOptions = { style: 'currency', currency: 'RUB' };

	const [cost, setCost] = useState({ payment: '', cent: '' });
	// const [costBalance, setCostBalance] = useState({ payment: '', cent: '' });
	const [orgList, setOrgList] = useState([]);
	const [creditFormList, setCreditFormList] = useState([]);
	const [tranchesList, setTranchesList] = useState([]); //список всех траншей
	const [originalOrg, setOriginalOrg] = useState('');
	const [message, setMessage] = useState('');

	const currentTranches = tranchesList?.filter(
		(el) => el?.org?._id === tranche?.org
	); //находит транши выбранной организации

	// const limitDebt = currentTranches?.length
	// 	? currentTranches?.reduce(
	// 			(accumulator, currentValue) => accumulator + currentValue.debt,
	// 			0.0
	// 	  )
	// 	: 0; // подсчитываем задолженность всех траншей выбранной организации

	const limitBalance = currentTranches?.length
		? currentTranches?.reduce(
				(accumulator, currentValue) => accumulator + currentValue.balance,
				0.0
		  )
		: 0; // подсчитываем текущую задолженность всех траншей выбранной организации
	const currentOrg = orgList.find((el) => el._id === tranche.org); //находим выбранную организации из общего списка

	// const currentRepay = currentOrg?.totalRepay || 0;

	// const checkOrg = tranche.org === originalOrg ? true : false; // проверка на изначальную организацию, на случай если компания была изменена

	useEffect(() => {
		async function getDirectories() {
			try {
				const org = await axiosPrivate.get(`${organizationPrefix}/complete`);
				const creditForms = await axiosPrivate.get(creditFormPrefix);
				const tranche = await axiosPrivate.get(tranchePrefix);
				setCreditFormList(creditForms.data);
				setOrgList(org.data);
				setTranchesList(tranche?.data);
			} catch (error) {
				console.log(error);
			}
		}
		async function getTrancheById() {
			try {
				const { data } = await axiosPrivate.get(`${tranchePrefix}/${id}`);
				setTranche({
					org: data.org._id,
					date: data.date,
					trancheDay: data.trancheDay,
					creditForm: data.creditForm._id,
					debt: data.debt,
					balance: data.balance,
				});
				setOriginalOrg(data.org._id);
				const balance = data.balance.toFixed(2).split('.');
				setCost({ payment: balance[0], cent: balance[1] });
			} catch (error) {}
		}
		getTrancheById();
		getDirectories();
	}, []);

	const handleChange = (e) => {
		setTranche((prev) => ({ ...prev, [e.target.name]: e.target?.value }));
	};

	async function updateTranche(event) {
		try {
			event.preventDefault();
			const targetValue = document.getElementById('debt');
			const allDays = addDays(tranche.date, tranche.trancheDay);
			const returnTranche = calculateNearestPreviousWeekday(allDays);
			const curOrg = orgList.find((el) => el._id === tranche.org);
			const curCreditForm = creditFormList.find(
				(el) => el._id === tranche.creditForm
			);
			const form = new FormData(event.currentTarget);
			form.append('returnTranche', returnTranche);
			form.append('balance', targetValue.value);
			const f_data = Object.fromEntries(form.entries());

			f_data.org = curOrg;
			f_data.creditForm = curCreditForm;
			// const response = await axiosPrivate.put(`${tranchePrefix}/${id}`, f_data);
			axiosPrivate.put(`${tranchePrefix}/${id}`, f_data);
			navigate(-1);
			setMessage('');
		} catch (error) {
			setMessage(error?.response?.data?.message);
		}
	}

	return (
		<div className={classes.wrapper}>
			<h1>Редактировать задолженность</h1>
			<form onSubmit={updateTranche} className={classes.form}>
				<div>
					<InputLabel id="org">Организация</InputLabel>
					<Select
						labelId="org"
						id="org"
						sx={{ width: '100%' }}
						value={tranche.org}
						defaultValue={tranche.org}
						name="org"
						label="org"
						onChange={handleChange}
					>
						{orgList?.map((item) => (
							<MenuItem key={item?._id} value={item?._id} name={item?.name}>
								{item?.name}
							</MenuItem>
						))}
					</Select>
				</div>
				<div>
					<InputLabel id="creditForm">Форма долга</InputLabel>
					<Select
						sx={{ width: '100%' }}
						labelId="creditForm"
						id="creditForm"
						value={tranche.creditForm}
						name="creditForm"
						label="creditForm"
						onChange={handleChange}
					>
						{creditFormList?.map((item) => (
							<MenuItem key={item?._id} value={item?._id}>
								{item?.name}
							</MenuItem>
						))}
					</Select>
				</div>
				<div>
					<h3>Долговой лимит для организации:</h3>
					{new Intl.NumberFormat(loc, payOptions).format(
						currentOrg?.limit - limitBalance
					)}
				</div>
				<InputLabel>Сумма нанешнего долга</InputLabel>
				<InputWithCategory
					name="debt"
					startPayment={cost.payment}
					startCent={cost.cent}
				/>
				<div>
					<TextField
						fullWidth
						name="date"
						label="Дата операции"
						variant="outlined"
						onChange={handleChange}
						value={new Date(tranche?.date).toISOString().split('T')[0]}
						autoComplete="off"
						type="date"
					/>
				</div>
				<div>
					<TextField
						fullWidth
						name="trancheDay"
						label="Срок возврата (дней)"
						variant="outlined"
						onChange={handleChange}
						value={tranche?.trancheDay}
						autoComplete="off"
						type="number"
						inputProps={{ min: 0 }}
					/>
				</div>
				<ErrorMessage message={message} />
				<Button variant="contained" color="primary" size="large" type="submit">
					Принять
				</Button>
			</form>
		</div>
	);
};
