import axios from 'axios';
import config from '../config/config';

export const { ApiUrl, RefreshUrl } = config;

const $api = axios.create({
    withCredentials: true,
    baseURL: ApiUrl
})


$api.interceptors.response.use((config) => {
    return config;
},async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && error.config && !error.config._isRetry) {
        /*проверить есть ли ацес токен
        *проверить есть ли рефреш
        * /refresh
        * перенаправить на прошлый вызов
        */
        const { rt } = document.cookie;
        console.log('cookie ', rt)
        originalRequest._isRetry = true;
        try {
            if(rt){ 
                const response = await axios.get(`${ApiUrl}${RefreshUrl}`)
            console.log(response)
        }
            return $api.request(originalRequest);
        } catch (e) {
            console.log('НЕ АВТОРИЗОВАН')
        }
    }
    throw error;
})

export default $api;
