import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, TextField } from '@mui/material';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import config from '../../../config/config';
import { InputWithCategory } from '../../../Components/InputWithCategory/InputWithCategory';

import classes from './EditOrganization.module.css';

const { organizationPrefix } = config;

export const EditOrganization = () => {
	const navigate = useNavigate();
	const axiosPrivate = useAxiosPrivate();
	const { id } = useParams();

	const [organizations, setOrganizations] = useState({
		name: '',
		inDate: new Date(),
		limit: 0,
	});
	const [cost, setCost] = useState({ payment: '', cent: '' });

	const [message, setMessage] = useState('');

	const handleChange = (e) => {
		setOrganizations((prev) => ({ ...prev, [e.target.name]: e.target?.value }));
	};

	useEffect(() => {
		const getOrganizationById = async () => {
			try {
				const response = await axiosPrivate.get(`${organizationPrefix}/${id}`);
				setOrganizations(response.data);
				const limit = response.data.limit.toFixed(2).split('.');
				setCost({ payment: limit[0], cent: limit[1] });
			} catch (error) {}
		};
		getOrganizationById();
	}, []);

	async function updateOrganization(event) {
		try {
			event.preventDefault();
			const form = new FormData(event.currentTarget);
			const f_data = Object.fromEntries(form.entries());
			const response = await axiosPrivate.put(
				`${organizationPrefix}/${id}`,
				f_data
			);
			navigate(-1);
			setMessage('');
		} catch (error) {
			setMessage(error?.response?.data?.message);
		}
	}

	return (
		<div className={classes.wrapper}>
			<h1>Редактировать организацию</h1>
			<form className={classes.form} onSubmit={updateOrganization}>
				<TextField
					fullWidth
					name="name"
					variant="outlined"
					label="Название компании"
					onChange={handleChange}
					value={organizations?.name}
					autoComplete="off"
				/>

				<InputWithCategory
					name="limit"
					startPayment={cost.payment}
					startCent={cost.cent}
				/>

				<Button variant="contained" color="primary" size="large" type="submit">
					Подтвердить
				</Button>
			</form>
		</div>
	);
};
