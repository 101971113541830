import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './login.css';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import config from '../../config/config';
import useAuth from '../../hooks/useAuth';

const { ApiUrl } = config;

const LoginForm = () => {
	// const [user, setUser] = useState({
	//   email:'sagdinov.a@yandex.ru',
	//   password:'Paralaxx99'
	// })
	const [user, setUser] = useState({});

	const { setAuth } = useAuth();

	const [message, setMessage] = useState('');

	const [errCookie, setErrCookie] = useState(false);
	let navigate = useNavigate();

	const handleChange = (e) => {
		if (message) {
			setMessage('');
		}
		setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	};

	const theme = createTheme();

	const checkCookie = () => {
		if (!navigator.cookieEnabled) {
			setErrCookie(true);
			// store.setMessage("Работа с cookie отключена")
			setMessage('Работа с cookie отключена');
		}
	};

	useEffect(() => {
		checkCookie();
	}, []);

	const handleLogin = async (email, password) => {
		try {
			setMessage('');
			await axios.post(
				`${ApiUrl}/users/login`,
				JSON.stringify({ email: email?.trim(), password: password?.trim() }),
				{
					headers: { 'Content-Type': 'application/json' },
					withCredentials: true,
				}
			);
			// localStorage.setItem('token', response?.data?.accessToken);
			setAuth(true);
			// this.setUser(response?.data?.user);
			setMessage('');
			navigate('/');
		} catch (error) {
			console.log(error);
			if (error.response) {
				if (error.response.data?.message) {
					setMessage(error.response.data.message);
				}
			} else {
				setMessage('Ошибка сервера, попробуйте позже');
			}
		}
	};

	const onSubmit = () => {
		if (user.email && user.password) {
			handleLogin(user.email, user.password);
		} else {
			setMessage('Все поля должны быть заполнены');
		}
	};

	const onKeyDown = (e) => {
		if (e.key === 'Enter') {
			onSubmit();
		}
	};

	return (
		<div className="containerLogin">
			<ThemeProvider theme={theme}>
				<Container component="main" maxWidth="xs">
					<CssBaseline />
					<Box
						sx={{
							marginTop: 8,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}
					>
						<Avatar sx={{ m: 1, bgcolor: 'pink' }}>
							<LockOutlinedIcon />
						</Avatar>

						<Typography component="h1" variant="h5">
							Вход
						</Typography>

						<Box component="form" sx={{ mt: 1 }} onKeyDown={onKeyDown}>
							<TextField
								margin="normal"
								required
								fullWidth
								id="email"
								label="Email Address"
								name="email"
								autoComplete="email"
								autoFocus
								onChange={handleChange}
								value={user.email}
							/>

							<TextField
								margin="normal"
								required
								fullWidth
								name="password"
								label="Password"
								type="password"
								id="password"
								autoComplete="current-password"
								onChange={handleChange}
								value={user.password}
							/>

							<Button
								fullWidth
								variant="contained"
								sx={{ mt: 3, mb: 2 }}
								color="primary"
								onClick={onSubmit}
								disabled={errCookie}
							>
								Войти
							</Button>
						</Box>
					</Box>

					<Box
						sx={{
							paddingTop: 1,
							display: 'flex',
							flexDirection: 'column',
							color: 'red',
						}}
					>
						<Typography component="h1" variant="h6" align="center">
							{/* {store.message} */}
							{message}
						</Typography>
					</Box>
				</Container>
			</ThemeProvider>
		</div>
	);
};
export default LoginForm;
