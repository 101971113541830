/**
 * Вычисляет суммарное значение долговых лимитов организаций на основе фильтра по имени организации.
 * @param {Array.<object>} orgList - Список организаций.
 * @param {Array.<string>} filterOrgNames - Фильтр по имени организации.
 * @returns {number} - Суммарный долговой лимит организаций.
 */
export const getOrgLimitByFilterName = (orgList, filterOrgNames) => {
	return orgList.reduce((acc, org) => {
		return acc + (filterOrgNames.includes(org.name) ? org.limit : 0);
	}, 0);
};

// Остаток долгового лимита для организации
export const getRestOrgLimit = (tranches, orgLimit) => {
	const summBalance = tranches.reduce(
		//Проверяем трашн фактический или запланированный
		(acc, trance) => acc + (trance.real ? trance.balance : 0),
		0
	);
	return orgLimit - summBalance;
};
