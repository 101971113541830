import { Routes, Route } from 'react-router-dom';
import Layout from './Pages/Layout/Layout';
import { OrganizationsList } from './Pages/OrganizationsList/OrganizationsList';
import { CreditFormList } from './Pages/CreditForm/CreditFormList';
import { AddCreditForm } from './Pages/CreditForm/AddCreditForm/AddCreditForm';
import { EditCreditForm } from './Pages/CreditForm/EditCreditForm/EditCreditForm';
import { AddOrganization } from './Pages/OrganizationsList/AddOrganization/AddOrganization';
import { EditOrganization } from './Pages/OrganizationsList/EditOrganization/EditOrganization';
import { Tranches } from './Pages/Tranches.jsx/Tranches';
import { AddTranche } from './Pages/Tranches.jsx/AddTranche/AddTranche';
import { EditTranche } from './Pages/Tranches.jsx/EditTranche/EditTranche';
import { Transactions } from './Pages/Transactions/Transactions';
import { AddTransaction } from './Pages/Transactions/AddTransaction/AddTransaction';
import { UpdateTransaction } from './Pages/Transactions/UpdateTransaction/UpdateTransaction';
import { Reports } from './Pages/Reports/Reports';
import { ReportsTable } from './Pages/Reports/ReportsTable';
import { ReportWithCurrLimit } from './Pages/Reports/ReportWithCurrLimit';
import UserList from './Pages/userDomain/userList/UserList';
import User from './Pages/userDomain/user/User';
import NewUser from './Pages/userDomain/newUser/NewUser';
import { AuthProvider } from './context/AuthProvider';
import PersistLogin from './hoc/PersistLogin';
import LoginForm from './Pages/login/Login';
import './App.css';

function App() {
	return (
		<AuthProvider>
			<Routes>
				<Route path="/" element={<Layout />}>
					<Route path="/login" element={<LoginForm />} />
					<Route element={<PersistLogin />}>
						<Route index element={<Tranches />} />
						<Route path="users" element={<UserList />} />
						<Route path="user/:id" element={<User />} />
						<Route path="newUser" element={<NewUser />} />
						<Route path="addTranche" element={<AddTranche />} />
						<Route path="/:id" element={<EditTranche />} />
						<Route path="transactions/:id" element={<Transactions />} />
						<Route
							path="transactions/createTransaction/:id"
							element={<AddTransaction />}
						/>
						<Route
							path="transactions/updateTransaction/:transacId/tranche/:trancheId"
							element={<UpdateTransaction />}
						/>
						<Route path="organizationsList" element={<OrganizationsList />} />
						<Route path="creditFormList" element={<CreditFormList />} />
						<Route path="creditFormList/:id" element={<EditCreditForm />} />
						<Route
							path="creditFormList/addCreditForm"
							element={<AddCreditForm />}
						/>
						<Route
							path="organizationsList/addOrganization"
							element={<AddOrganization />}
						/>
						<Route
							path="organizationsList/:id"
							element={<EditOrganization />}
						/>
						{/* <Route path="reports" element={<Reports />} /> */}
						{/* <Route path="reports" element={<ReportsTable />} /> */}
						<Route path="reports" element={<ReportWithCurrLimit />} />
					</Route>
				</Route>
			</Routes>
		</AuthProvider>
	);
}

export default App;
