import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, TextField } from '@mui/material';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import config from '../../../config/config';
import classes from './EditCreditForm.module.css';

const { creditFormPrefix } = config;

export const EditCreditForm = () => {
	const [dept, setDept] = useState({
		name: '',
	});
	const { id } = useParams();
	const axiosPrivate = useAxiosPrivate();
	const [message, setMessage] = useState('');

	const navigate = useNavigate();

	useEffect(() => {
		async function getCreditFormById() {
			try {
				const { data } = await axiosPrivate.get(`${creditFormPrefix}/${id}`);
				setDept({ name: data.name });
			} catch (error) {
				setMessage(error?.response?.data?.message);
				console.error(error?.response?.data?.message);
			}
		}
		getCreditFormById();
	}, []);

	const handleSubmit = async (event) => {
		try {
			event.preventDefault();
			setMessage('');
			const form = new FormData(event.currentTarget);
			const f_data = Object.fromEntries(form.entries());
			const response = await axiosPrivate.put(
				`${creditFormPrefix}/${id}`,
				f_data
			);
			navigate(-1);
		} catch (error) {
			setMessage(error?.response?.data?.message);
			console.error(error?.response?.data?.message);
		}
	};

	const handleChange = (e) => {
		setDept((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	};

	return (
		<>
			<div className={classes.newUser}>
				<h1 className={classes.newUserTitle}>Новая форма долга</h1>
				<form className={classes.newUserForm} onSubmit={handleSubmit}>
					<TextField
						type="text"
						placeholder="Введите название"
						label="Название"
						name="name"
						onChange={handleChange}
						required
						value={dept.name}
					/>
					<Button variant="contained" type="submit">
						Создать
					</Button>
				</form>
				<div className={classes.newUserErrorMessage}>{message}</div>
			</div>
		</>
	);
};
